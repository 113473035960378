import {
	Address,
	Business,
	Contact,
	Customer,
	Estimate,
	Installation,
	Job,
	JobCategories,
	JobLog,
	JobLogType,
	JobPhase,
	LossReasonCodes,
	Measurement,
	Prisma,
	PurchaseOrder,
	QuickbooksLog,
	Quote,
	ScheduledJob,
	Transaction,
	User,
	Document,
	BusinessCategory,
	Task,
	PurchaseOrderStatus,
	ScheduledJobType,
	UsersOnScheduledJobs,
	EstimateState,
	EstimateGroup,
	EstimateLineItem,
	Branch,
	QuickbooksClass,
	UsersOnJobs,
	KeyDate,
	QuoteSettings,
	CustomStage,
} from '@prisma/client';
import { z } from 'zod';
import { ScheduledJobForModalCard } from './scheduledJob.schema';

export type FormattedJobForProfitAndLoss = {
	deadline: string;
	address: string;
	category: string;
	updatedAt: string;
	isPickup: boolean;
	id: number;
	business?: {
		addressId: number;
	};
	businessId: number;
	customerId: number;
	customerInstallationContactId?: number;
	jobOwnerId: number;
	addressId: number;
	jobPhase: JobPhase;
	businessCategory: BusinessCategory;
	notes?: string;
	workOrderNotes?: string;
	name: string;
	lead?: string;
	description?: string;
	lossReason?: string;
	billingDayOfMonth?: number;
	retainage?: number;
	lossReasonCode?: LossReasonCodes;
	createdAt: Date;
	deletedAt?: Date;
	customer: Customer & {
		email?: string | null;
		phone?: string | null;
	};
	jobOwner: User;
	estimates: Estimate[];
	isCommercial?: boolean;
	contactName?: string;
	lastStatus?: JobLogType;
	lastStatusTime?: Date;
	estimatedTotal?: number;
	estimateBalance?: number;
	materialsTotal?: number;
	laborTotal?: number;
	otherTotal?: number;
	purchaseOrderTotal?: number;
	techniciansOnJob?: { firstName: string; lastName: string }[];
	purchaseOrders?: { status: PurchaseOrderStatus; businessSupplier: { supplier: { name: string } } }[];
	problemReason: string | null;
};

export type FormattedJob = {
	deadline: string;
	address: string;
	category: string;
	updatedAt: string;
	isPickup: boolean;
	id: number;
	business?: {
		addressId: number;
	};
	branch?: Branch;
	businessId: number;
	customerId: number;
	customerInstallationContactId?: number;
	jobOwnerId: number;
	addressId: number;
	jobPhase: JobPhase;
	businessCategory: BusinessCategory;
	notes?: string;
	workOrderNotes?: string;
	name: string;
	lead?: string;
	description?: string;
	lossReason?: string;
	billingDayOfMonth?: number;
	retainage?: number;
	lossReasonCode?: LossReasonCodes;
	createdAt: Date;
	deletedAt?: Date;
	promiseDate?: Date;
	customer: Customer & {
		email?: string | null;
		phone?: string | null;
		billingAddress?: string;
	};
	jobOwner: User;
	estimates: Estimate[];
	isCommercial?: boolean;
	contactName?: string;
	lastStatus?: JobLogType;
	lastStatusTime?: Date;
	estimatedTotal?: number;
	estimateBalance?: number;
	Task?: Task[];
	purchaseOrders?: { status: PurchaseOrderStatus; businessSupplier: { supplier: { name: string } } }[];
	problemReason: string | null;
	inWarrantyUntil: string | null;
	customStageId: number | null;
};

export enum JobSubView {
	OVERVIEW = 'OVERVIEW',
	ALL = 'ALL',
	ALL_OPPORTUNITIES = 'OPPORTUNITIES',
	ALL_WON_JOBS = 'WON_JOBS',
	FLAGGED = 'FLAGGED',
	CLOSED = 'CLOSED',
	READY_TO_MEASURE = 'READY_TO_MEASURE',
	READY_TO_INSTALL = 'READY_TO_INSTALL',
	READY_TO_ORDER = 'READY_TO_ORDER',
	READY_TO_INVOICE = 'READY_TO_INVOICE',
	CREATED = 'CREATED',
	QUOTE_SENT = 'QUOTE_SENT',
	QUOTE_APPROVED = 'QUOTE_APPROVED',
	MEASURE_SCHEDULED = 'MEASURE_SCHEDULED',
	MEASURE_TAKEN = 'MEASURE_TAKEN',
	DEPOSIT_PAID = 'DEPOSIT_PAID',
	ON_ORDER = 'ON_ORDER',
	PO_RECEIVED = 'PO_RECEIVED',
	PO_REJECTED = 'PO_REJECTED',
	PO_CHECKED_IN = 'PO_CHECKED_IN',
	INSTALL_SCHEDULED = 'INSTALL_SCHEDULED',
	INSTALL_DONE = 'INSTALL_DONE',
	INVOICED = 'INVOICED',
	BILLING_APPROACHING = 'BILLING_APPROACHING',
	UNPAID_PO = 'UNPAID_PO',
}

//Front page jobs
export type JobSummary = Job & {
	address?: Address | null;
	customer: Customer;
	jobOwner: User;
};

export type JobFull = Job & {
	isCommercial?: boolean;
	customer:
		| (Customer & {
				billingAddress: Address | null;
				contacts: Contact[] | null;
		  })
		| null;
	address: Address | null;
	jobOwner: User | null;
	contacts: Contact[] | null;
	quickbooksClass?: QuickbooksClass | null;
	JobLog: JobLog[];
	business:
		| (Business & {
				address: Address | null;
				quoteSettings: QuoteSettings | null;
		  })
		| null;
	branch: Branch | null;
	usersOnJobs?: (UsersOnJobs & {
		assignedUser: User;
	})[];
	// Helper Properties which will make it easier for the front
	// end to schedule jobs.
	ScheduledJobs?:
		| (ScheduledJob & {
				usersOnScheduledJob: UsersOnScheduledJobs[];
		  })[]
		| null;
	scheduledJob?:
		| (ScheduledJob & {
				assignedUserIds?: number[];
				assignedUsers?: User[];
		  })
		| null;
	// NB: should only include the single active stage
	JobCustomStage?: { stageId: number }[];
	KeyDates?: KeyDate[];
};

export type JobServiceAllOptions = {
	limit?: number;
	cursor?: number;
	onlyGetConsequentialJobLogs?: boolean;
	includeRelatedData?: boolean;
	includeProfitAndLossData?: boolean;
};

export type ScheduleModalJob = {
	id: number;
	jobPhase: JobPhase;
	name: string;
	description: string | null;
	category: JobCategories;
	businessCategory: BusinessCategory | null;
	createdAt: Date;
	updatedAt: Date | null;
	address: Address | null;
	customer: {
		id: number;
		name: string;
		contacts: {
			id: number;
			name: string;
			email: string | null;
			phone: string | null;
		}[];
	};
	jobOwner: {
		email: string | null;
		phone: string;
		firstName: string;
		lastName: string;
	};
	ScheduledJobs: ScheduledJobForModalCard[];
	_count: {
		ScheduledJobs: number;
		measurements: number;
		installations: number;
		purchaseOrders: number;
	};
};

export type CustomerWithContactInfo = Customer & {
	phone?: string | null;
	email?: string | null;
};

export type JobForSearch = Job & {
	business?: Business | null | undefined;
	measurements?: Measurement[] | undefined;
	address?: Address | null;

	Document?: Document[] | undefined;
	jobOwner?: User | undefined;
	customer?: (Customer & { contacts?: Contact[]; billingAddress?: Address }) | undefined;
	JobLog?: JobLog[] | undefined;
	estimates?: (Estimate & { estimateGroups?: (EstimateGroup & { estimateLineItems: EstimateLineItem[] })[] })[] | undefined;
	approvedChangeOrders?: Estimate[] | undefined;
	quotes?: Quote[] | undefined;
	JobCustomStage?: { stageId: number }[] | undefined;
	QuickbooksLogs?: QuickbooksLog[] | undefined;
	customerInstallationContact?: Contact | null | undefined;
	purchaseOrders?: PurchaseOrder[] | undefined;
	installations?: Installation[] | undefined;
	ScheduledJobs?:
		| (ScheduledJob & { usersOnScheduledJob?: (UsersOnScheduledJobs & { assignedUser?: User; additionalUsers?: User[] })[] })[]
		| undefined;
	Transactions?: Transaction[] | undefined;
	businessCategory: BusinessCategory;
	problemReason: string | null;
	promiseDate?: Date;
	inWarrantyUntil?: string | undefined;
	_count?: Prisma.JobCountOutputType | undefined;
};

export type JobThin = Job & {
	address?: Address | null;
	jobOwner?: User | null;
	customer?: (Customer & { contacts?: Contact[]; billingAddress?: Address }) | undefined;
	contacts?: Contact[] | null;
	estimates?: Estimate[] | null;
	branch?: Branch | null;
	JobCustomStage?: { stageId: number; stage: CustomStage }[] | null;
	purchaseOrders?: PurchaseOrder[] | null;
};

export type JobForDashboard = Job & {
	estimates?: Estimate[];
	estimateTotal?: number;
	estimateBalance?: number;
};

export type JobResultOfGlobalSearch = {
	id: number;
	businessCategory?: BusinessCategory;
	customer: {
		name: string;
		isCommercial: boolean;
	};
	name: string;
	jobPhase?: JobPhase;
	jobOwner?: {
		firstName: string;
		lastName: string;
	};
	totalCategoryCount: number;
	inWarrantyUntil?: string | null | undefined;
	warrantyDurationDays?: number | null | undefined;
	warrantyStartDate?: Date | null | undefined;
};

export type JobBoardThin = {
	id: number;
	businessId: number;
	jobPhase: JobPhase;
	name: string;
	category: JobCategories;
	description: string | null;
	deadline: Date | null;
	lastStatus: JobLogType | null;
	lastStatusTime: Date | null;
	jobOwner: { id: number; firstName: string; lastName: string };
	customer: {
		name: string;
	};
};

// Option A
// Now we need to bucket the jobs accordingly.
export interface JobBucketResponse {
	[key: string]: JobBoardThin[];
	QuoteSent2DaysAgo: JobBoardThin[]; // includes quotes sent between 2 days and 1 week ago (that are not marked as lost or closed)
	QuoteSent1WeekAgo: JobBoardThin[]; // includes quotes sent between 1 week and 1 month ago (..)
	QuoteSent1MonthAgo: JobBoardThin[]; // includes quotes sent more than 1 month ago (..)
	WonJobsNoDeposit: JobBoardThin[]; // added this one
	WonJobsNoMeasurement: JobBoardThin[]; // replaces WonJobsNoMeasurementsTakenBalance
	WonJobsNoPurchaseOrder: JobBoardThin[];
	MeasurementsTakenWasLastAction: JobBoardThin[];
	PurchaseOrdersNeedConfirmation: JobBoardThin[];
	WaitingForPurchaseOrders: JobBoardThin[];
	AllPurchaseOrdersArrived: JobBoardThin[]; // (Was Last Action ie. nothing else happened)
	FabricationCompleteWasLastAction: JobBoardThin[]; // added this one
	InstallCompleteWasLastAction: JobBoardThin[]; // replaces InstallComplete
	WonJobsOutstandingBalance: JobBoardThin[];
}

export type KeyDateForStepper = Pick<KeyDate, 'completedAt' | 'dueDate' | 'name'> & {
	id?: number;
};

/**
 * Job log types to surface in summaries, use in searches, etc
 */
export const ConsequentialJobLogTypes = new Array<JobLogType>(
	JobLogType.JOB_LOST,
	JobLogType.JOB_COMPLETE,
	JobLogType.UNDO_JOB_COMPLETE,
	JobLogType.UNDO_JOB_LOST,
	JobLogType.WORK_ORDER_CREATED,
	JobLogType.JOB_SCHEDULED,
	JobLogType.ESTIMATE_CREATED,
	JobLogType.ESTIMATE_GENERATED,
	JobLogType.ESTIMATE_SENT,
	JobLogType.ESTIMATE_WON,
	JobLogType.UNDO_ESTIMATE_WON,
	JobLogType.DEPOSIT_PAID,
	JobLogType.PO_CREATED,
	JobLogType.PO_SENT,
	JobLogType.PO_CONFIRMED,
	JobLogType.PO_REJECTED,
	JobLogType.PO_ACCEPTED,
	JobLogType.PO_PARTIALLY_ACCEPTED,
	JobLogType.PO_FINALIZED,
	JobLogType.PO_PAID,
	JobLogType.FINAL_INVOICE_SENT,
	JobLogType.FINAL_INVOICE_PAID,
	JobLogType.ACCOUNTING_COMPLETE,
	JobLogType.FABRICATION_COMPLETE,
	JobLogType.MEASUREMENT_CREATED,
	JobLogType.INSTALLATION_COMPLETE,
	JobLogType.JOB_CREATED,
	JobLogType.QUOTE_SENT,
	JobLogType.QUOTE_APPROVED
);

export class JobSchema {
	static addressInput = z.object({
		id: z.number().nullable().optional(),
		street1: z.string(),
		street2: z.string().optional(),
		city: z.string(),
		state: z.string().optional(),
		province: z.string().optional(),
		zip: z.string(),
		country: z.string().optional(),
		latitude: z.number().optional(),
		longitude: z.number().optional(),
	});

	static jobsFiltersSchema = z
		.object({
			phases: z.array(z.string()).optional(),
			assignedUserIds: z.array(z.string()).optional(),
			jobTypes: z.array(z.string()).optional(),
			// The last log type for this job is one of these types
			lastLogTypes: z.array(z.nativeEnum(JobLogType)).optional(),
			// At least one of the following log types exist for this job
			anyLogTypes: z.array(z.nativeEnum(JobLogType)).optional(),
			// All purchase orders are one of these states (only use one purchase order filter at a time)
			purchaseOrderStates: z.array(z.nativeEnum(PurchaseOrderStatus)).optional(),
			// At least one purchase order is in one of these states (only use one purchase order filter at a time)
			somePurchaseOrderStates: z.array(z.nativeEnum(PurchaseOrderStatus)).optional(),
			// At least one purchase order is not one of these states (only use one purchase order filter at a time)
			notAllPurchaseOrderStates: z.array(z.nativeEnum(PurchaseOrderStatus)).optional(),
			// At least one future scheduled job is one of these types
			futureScheduledJobTypes: z.array(z.nativeEnum(ScheduledJobType)).optional(),
			// No future scheduled jobs are one of these types
			disallowedScheduledJobTypes: z.array(z.nativeEnum(ScheduledJobType)).optional(),
			// At least one estimate is of one of these states
			estimateStates: z.array(z.nativeEnum(EstimateState)).optional(),
			// At least one estimate has a remaining balance
			estimateRequiresBalance: z.boolean().optional(),
			// The monthly billing date for the job is within 7 days
			requireBillingApproaching: z.boolean().optional(),
			// The job must have a problem reason
			requireProblemReason: z.boolean().optional(),
			stageId: z.number().optional(),
			branchIds: z.array(z.number()).optional(),
		})
		.optional();

	static allJobsQueryInputSchema = z
		.object({
			filters: JobSchema.jobsFiltersSchema,
			sortBy: z.string().optional(),
			sortDirection: z.string().optional(),
			searchQuery: z.string().optional(),
			businessCategory: z.nativeEnum(BusinessCategory).optional(),
		})
		.optional();

	static boardViewQueryInputSchema = z.object({
		limit: z.number(),
		cursor: z.number().optional(),
		jobLogType: z.nativeEnum(JobLogType),
		filters: z
			.object({
				phases: z.array(z.string()).optional(),
				assignedUserIds: z.array(z.string()).optional(),
				jobTypes: z.array(z.string()).optional(),
				lastLogTypes: z.array(z.nativeEnum(JobLogType)).optional(),
			})
			.optional(),
		businessCategory: z.nativeEnum(BusinessCategory),
		searchQuery: z.string().optional(),
	});

	static frontPageQueryInputSchema = z.object({
		limit: z.number(),
		cursor: z.number().optional(),
		filters: JobSchema.jobsFiltersSchema,
		sortBy: z.string().optional(),
		sortDirection: z.string().optional(),
		searchQuery: z.string().optional(),
		businessCategory: z.nativeEnum(BusinessCategory).optional(),
	});

	static frontPageCountQueryInputSchema = z.object({
		limit: z.number(),
		cursor: z.number().optional(),
		filtersByJobSubView: z.object({
			[JobSubView.ALL]: JobSchema.jobsFiltersSchema,
			[JobSubView.ALL_OPPORTUNITIES]: JobSchema.jobsFiltersSchema,
			[JobSubView.ALL_WON_JOBS]: JobSchema.jobsFiltersSchema,
			[JobSubView.FLAGGED]: JobSchema.jobsFiltersSchema,
		}),
		searchQuery: z.string().optional(),
		businessCategory: z.nativeEnum(BusinessCategory).optional(),
	});

	static contact = z.object({
		contactId: z.number().optional(),
		contactName: z.string(),
		contactPhone: z.string().optional(),
		contactEmail: z.string().optional(),
		contactLabel: z.string().optional(),
		isDefaultContact: z.boolean(),
		includeInJob: z.boolean().optional(),
	});

	static customerDetails = z.object({
		customerId: z.number().optional(),
		customerName: z.string(),
		customerPhone: z.string().optional(),
		customerEmail: z.string().optional(),
		customerBusinessName: z.string().optional(),
		customerAddress: this.addressInput.optional(),
		billingAddress: this.addressInput.optional(),
		isCommercial: z.boolean(),
	});

	static createNewJobSchema = z.object({
		customerDetails: JobSchema.customerDetails,
		contactDetails: z.array(JobSchema.contact),
		isPickup: z.boolean(),
		property: z
			.object({
				id: z.number().optional(),
			})
			.optional()
			.nullable(),
		units: z.array(z.number()).optional().nullable(),
		jobDetails: z.object({
			jobName: z.string(),
			jobCategory: z.nativeEnum(JobCategories),
			jobDescription: z.string().optional(),
			jobOwnerId: z.number(),
			additionalAssignees: z.array(z.object({ text: z.string(), value: z.number() })),
			quickbooksClassId: z.number().optional(),
			jobAddress: this.addressInput.optional(),
			jobStatus: z.string().optional(),
			jobDeadline: z.string().optional(),
			jobPhase: z.nativeEnum(JobPhase),
			jobLead: z.string().optional(),
			isCommercial: z.boolean().default(false),
			businessCategory: z.nativeEnum(BusinessCategory),
			promiseDate: z.date().optional(),
			branchId: z.number().optional(),
		}),
		quickEstimateId: z.number().optional(),
	});

	static editSchema = z.object({
		name: z.string().optional(),
		category: z.nativeEnum(JobCategories).optional(),
		description: z.string().optional(),
		problemReason: z.string().optional(),
		schedulingNote: z.string().optional(),
		jobOwnerId: z.number().optional(),
		quickbooksClassId: z.number().optional(),
		branchId: z.number().optional(),
		notes: z.string().optional(),
		lossReason: z.string().optional(),
		lossReasonCode: z.nativeEnum(LossReasonCodes).optional(),
		jobPhase: z.nativeEnum(JobPhase).optional(),
		lead: z.string().optional(),
		workOrderNotes: z.string().optional(),
		isCommercial: z.boolean().optional(),
		isPickup: z.boolean().optional(),
		architect: z.string().optional(),
		billingDayOfMonth: z.number().optional(),
		contractDate: z.date().optional(),
		retainagePercent: z.number().optional(),
		commercialBillingToggle: z.boolean().optional(),
		customerId: z.number().optional(),
		businessCategory: z.nativeEnum(BusinessCategory).optional(),
		promiseDate: z.date().optional().nullable(),
		readyToMeasure: z.boolean().optional(),
		readyToInstall: z.boolean().optional(),
		readyToOrder: z.boolean().optional(),
		readyToInvoice: z.boolean().optional(),
		warrantyDurationDays: z.number().optional(),
		warrantyStartDate: z.date().optional().nullable(),
	});

	static defaultJobSelect = Prisma.validator<Prisma.JobSelect>()({
		id: true,
		business: true,
		businessId: true,
		customer: true,
		customerId: true,
		contacts: true,
		jobOwner: true,
		jobOwnerId: true,
		problemReason: true,
		address: true,
		addressId: true,
		name: true,
		category: true,
		description: true,
		deadline: true,
		createdAt: true,
		updatedAt: true,
		deletedAt: true,
		promiseDate: true,
		jobPhase: true,
		lostAt: true,
		completedAt: true,
		notes: true,
		lead: true,
		lossReason: true,
		lossReasonCode: true,
		workOrderNotes: true,
		isCommercial: true,
		architect: true,
		billingDayOfMonth: true,
		contractDate: true,
		retainagePercent: true,
		lastStatus: true,
		lastStatusTime: true,
		isPickup: true,
		isTemplate: true,
		businessCategory: true,
		workflowStatus: true,
		readyToMeasure: true,
		readyToInstall: true,
		readyToOrder: true,
		readyToInvoice: true,
		warrantyDurationDays: true,
		warrantyStartDate: true,
		branch: true,
		branchId: true,
	});

	static jobContextSelect = Prisma.validator<Prisma.JobSelect>()({
		id: true,
		name: true,
		jobPhase: true,
		branch: true,
		addressId: true,
		businessId: true,
		isCommercial: true,
		architect: true,
		billingDayOfMonth: true,
		contractDate: true,
		retainagePercent: true,
		isPickup: true,
		problemReason: true,
		contacts: true,
		warrantyDurationDays: true,
		warrantyStartDate: true,
		customer: {
			select: {
				name: true,
				billingAddressId: true,
				billingAddress: true,
				contacts: true,
				isCommercial: true,
			},
		},
		estimates: {
			where: {
				deletedAt: null,
			},
			select: {
				id: true,
				estimateType: true,
				estimateState: true,
				version: true,
			},
		},
		customerId: true,
		address: true,
		notes: true,
		business: {
			select: {
				quoteSettings: true,
				address: true,
				addressId: true,
				depositPercent: true,
				emailSignature: true,
				estimateTerms: true,
				exclusions: true,
				inclusions: true,
				invoiceTerms: true,
				jobsUseQBClass: true,
				name: true,
				phone: true,
				tilledAccountId: true,
			},
		},
	});
}
