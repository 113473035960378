import {
	Address,
	Business,
	BusinessSupplier,
	Contact,
	Customer,
	Estimate,
	Job,
	Measurement,
	PurchaseOrder,
	Role,
	ScheduledJob,
	ScheduledJobClock,
	Supplier,
	Unit,
	User,
} from '@prisma/client';
import { ScheduledJobFull } from './scheduledJob.schema';
import { z } from 'zod';

export enum FieldJobType {
	INSTALL = 1,
	PURCHASE_ORDER,
	FABRICATION,
	MEASUREMENT,
	UNSCHEDULED,
	CUSTOM,
}

export enum FilterDisplayOptions {
	MY_JOBS = 'My Jobs',
	SCHEDULED = 'Scheduled',
	ALL_JOBS = 'All Jobs',
	PURCHASE_ORDERS = 'Purchase Orders',
	SHOP_WORK = 'Fabrication',
	UNSCHEDULED_JOBS = 'Open Jobs',
}

export type FieldJobPurchaseOrder = Pick<
	PurchaseOrder,
	'id' | 'poNumber' | 'externalPONumber' | 'actualArrivalDate' | 'expectedArrivalDate' | 'anticipatedCost' | 'actualCost' | 'status'
> & {
	businessSupplier: Partial<BusinessSupplier> & {
		supplier: Pick<Supplier, 'name'>;
	};
};

//For use within the FieldService class when querying initial jobs
export type FieldJobRaw = Pick<Job, 'id' | 'deadline' | 'name' | 'jobPhase' | 'problemReason'> & {
	address: Address | null;
	customer?: Customer & {
		contacts: Contact[];
	};
	jobOwner: User;
	purchaseOrders?: FieldJobPurchaseOrder[];
	ScheduledJobs?: (ScheduledJob & {
		usersOnScheduledJob: {
			assignedUser: {
				id: number;
				email: string | null;
				phone: string;
				firstName: string;
				lastName: string;
			};
			additionalUsers: {
				id: number;
				email: string | null;
				phone: string;
				firstName: string;
				lastName: string;
			}[];
		}[];
		ScheduledJobClock: ScheduledJobClock[];
	})[];
	estimates: Estimate[];
};

export type FieldJobCard = {
	jobCardId: string; //Use for react key, frontend ID generation

	jobId: number;

	job: Pick<Job, 'name' | 'deadline' | 'id' | 'jobPhase' | 'problemReason'> & {
		jobOwner: Pick<User, 'id' | 'firstName' | 'lastName' | 'phone'>;
		customer?: Customer & {
			contacts: Contact[];
		};
	};

	purchaseOrder?: FieldJobPurchaseOrder;
	supplierName?: string;

	customer?: Customer & {
		contacts: Contact[];
	};
	address?: Address;
	estimates: Estimate[];

	fieldJobType: FieldJobType;
	customJobType?: string | null;

	scheduledJob?: ScheduledJob & {
		usersOnScheduledJob: {
			assignedUser: {
				id: number;
				email: string | null;
				phone: string;
				firstName: string;
				lastName: string;
			};
			additionalUsers: {
				id: number;
				email: string | null;
				phone: string;
				firstName: string;
				lastName: string;
			}[];
		}[];
		ScheduledJobClock: ScheduledJobClock[];
	};

	isOverdue?: boolean;
	isComplete?: boolean;

	start?: Date | null;
	startTime?: string;

	end?: Date | null;
	endTime?: string;

	startedAt?: Date | null;
	completedAt?: Date | null;
};

export type FieldJobDetails = Job & {
	customer: Customer & {
		contacts: Contact[];
	};
	units: (Unit & {
		address: Address;
		contact: Contact;
	})[];
	address: Address | null;
	jobOwner: User;
	business:
		| (Business & {
				address: Address | null;
		  })
		| null;
	contacts: Contact[];
	ScheduledJobs: ScheduledJobFull[];
	purchaseOrders: FieldJobPurchaseOrder[];
	measurements: Measurement[];
	hasEstimate: boolean;
	hasPurchaseOrdersToCheckIn: boolean;
	userRole: Role;
};

export const AllTasksQueryInput = z.object({
	date: z.date(),
	filters: z.object({
		search: z.string().optional(),
		checkedDisplayOpts: z.array(z.string()),
		checkedJobTypes: z.array(z.number()),
		checkedCustomers: z.array(z.number()),
	}),
	limit: z.number(),
	cursor: z.number().optional(),
});

export type AllTasksQueryInputType = z.infer<typeof AllTasksQueryInput>;
